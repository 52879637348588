import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Form, Formik } from "formik";
import { residenceSchema } from "lib/validation/recipientSchemas";
import { Residence } from "interfaces/front/business";
import { FrontDialogTitle } from "components/front/Form/FrontDialogTitle";
import { TextField, TwoColumn } from "components/front/layout/DialogFields";
import { SearchAddressButton } from "components/front/order/recipient/SearchAddressButton";
import GenderField from "components/front/order/recipient/fields/GenderField";
import BirthdayField from "components/front/order/recipient/fields/BirthdayField";
import useDeliverable from "lib/hooks/front/useDeliverable";

export type Props = {
  isOpen: boolean;
  closeHandle: (residence?: Residence) => void;
  initialValue?: Residence;
  submitLabel?: string;
  courseIds: number[];
};

const ResidenceDialog: React.FC<Props> = ({ initialValue = defaultValue, closeHandle, isOpen, submitLabel, courseIds }) => {
  const { undeliverableText, onSubmit, onClose } = useResidenceDialogStates(courseIds, closeHandle);
  return (
    <Dialog fullWidth onClose={onClose} open={isOpen} scroll="body">
      <Formik<Residence> initialValues={initialValue} validationSchema={residenceSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue, isSubmitting, dirty }): React.ReactNode => (
          <Form>
            <FrontDialogTitle title="住民票情報の編集" onClose={(): void => closeHandle(undefined)} />
            <DialogContent>
              <TwoColumn>
                <TextField label="氏名(姓)" name="name_sei" placeholder="秋葉" />
                <TextField label="氏名(名)" name="name_mei" placeholder="太郎" />
              </TwoColumn>
              <TwoColumn>
                <TextField label="カナ(姓)" name="kana_sei" placeholder="アキバ" />
                <TextField label="カナ(名)" name="kana_mei" placeholder="タロウ" />
              </TwoColumn>
              <StyledGenderField name="gender" label="性別" />
              <StyledBirthdayField name="birthday" label="生年月日" fullWidth />
              <TextField fullWidth label="電話番号(ハイフンなし)" name="phone_number" placeholder="09012345678" />
              <TextField fullWidth label="郵便番号(ハイフンなし)" name="zip_code" placeholder="1010021" />
              <SearchAddressButton values={values} setFieldValue={setFieldValue} />
              <TextField fullWidth label="都道府県" name="prefectures" placeholder="東京都" />
              <TextField fullWidth label="市区町村" name="city" placeholder="千代田区" />
              <TextField fullWidth label="以降の住所" name="house_number" placeholder="外神田〇〇〇番地◯丁目" />
              <TextField fullWidth label="建物名" name="building_name" placeholder="〇〇マンション 101" />
              {undeliverableText && <ErrorMessage>{undeliverableText}</ErrorMessage>}
            </DialogContent>
            <DialogActions>
              <Button disabled={isSubmitting || !dirty} variant={"contained"} type="submit" color="primary" fullWidth>
                {submitLabel ? submitLabel : "決定"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default ResidenceDialog;

const useResidenceDialogStates = (courseIds: number[], closeHandle: (residence?: Residence) => void) => {
  const { undeliverableText, resetUndeliverable, checkDeliverable } = useDeliverable(courseIds);
  const onSubmit = useCallback(
    async (values: Residence) => {
      const deliverable = await checkDeliverable(values);
      if (deliverable) {
        closeHandle(values);
        resetUndeliverable();
      }
    },
    [checkDeliverable, closeHandle]
  );
  const onClose = useCallback(() => {
    closeHandle(undefined);
    resetUndeliverable();
  }, [resetUndeliverable, closeHandle]);
  return { undeliverableText, onSubmit, onClose };
};

const defaultValue: Residence = {
  name_sei: "",
  name_mei: "",
  kana_sei: "",
  kana_mei: "",
  phone_number: "",
  zip_code: "",
  prefectures: "",
  city: "",
  house_number: "",
  building_name: "",
  gender: "male",
  birthday: "",
};

const verticalMargin = css`
  margin: 10px 0 8px;
`;
const StyledGenderField = styled(GenderField)`
  ${verticalMargin};
`;
const StyledBirthdayField = styled(BirthdayField)`
  ${verticalMargin};
`;
const ErrorMessage = styled.div`
  color: #f44336;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 0.75rem; ;
`;
