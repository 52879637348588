import React, { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { Discount, HometownTaxOrderFormData, OrderCreateFormHometownTax } from "interfaces/front/page/orderForm";
import { withOrderNetworkError } from "lib/hoc/withOrderNetworkError";
import { useFrontAuthContext } from "lib/hoc/withFrontAuth";
import { useInitialOnestopFlag } from "lib/hooks/useInitialOnestopFlag";
import * as Yup from "yup";
import { array, object } from "yup";
import { onestopFlagSchema } from "lib/validation/onestopFlagSchema";
import { residenceSchema } from "lib/validation/recipientSchemas";
import { Field, Formik, FormikProps } from "formik";
import { Confirm, Content } from "components/front/order/form/OrderForm";
import OrderFormSection from "components/front/order/form/OrderFormSection";
import CartAmountArea from "components/front/order/form/CartAmountArea";
import useCartAmountInfo, { CartAmountInfo } from "lib/hooks/useCartAmountInfo";
import PaymentField from "components/front/order/payment/PaymentField";
import { CheckboxField } from "components/front/layout/CheckboxField";
import ResidenceField from "components/front/order/recipient/ResidenceField";
import DeliveryOptionField from "components/front/order/form/DeliveryOptionField";
import { HometownTaxNote } from "components/front/order/literal/CrowdFundingNote";
import { RuleLiteral } from "components/front/order/literal/RuleLiteral";
import WritesCommentField from "components/front/order/form/fields/WritesCommentField";
import OrderRealizeButtonArea from "./OrderRealizeButtonArea";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import useOrderItems from "lib/hooks/useOrderItems";
import OrderItemField from "components/front/order/form/fields/OrderItemField";
import useOrderFormOnSubmit from "lib/hooks/useOrderFormOnSubmit";
import useInitialPayment from "lib/hooks/useInitialPayment";
import { orderCourseOptionSchema } from "lib/validation/orderCourseOptionSchema";
import DiscountsField from "components/front/order/form/DiscountsField";

export type Props = { orderForm: HometownTaxOrderFormData };
const OrderFormHometownTax: FC<Props> = ({ orderForm }) => {
  const { onestopDeliverable, initialValues, validationSchema, onSubmit, isDeliveryOption } = useOrderFormHometownTaxStates(orderForm);
  return (
    <Formik<OrderCreateFormHometownTax>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(formik) => (
        <OrderFormHometownTaxFieldset orderForm={orderForm} formik={formik}>
          {({ courseIds, cartAmountInfo, handleAddDiscount }) => (
            <Content>
              <OrderFormSection title="応援する商品を確認">
                {formik.values.order_items.map((_orderItem, index) => (
                  <OrderItemField key={index} name={`order_items[${index}]`} cartItems={orderForm.cart_items} project={orderForm.project} />
                ))}
                {formik.values.discounts.length === 0 && (
                  <DiscountsField name="discounts" projectId={orderForm.project.id} onAdd={handleAddDiscount} />
                )}
                 <CartAmountArea info={cartAmountInfo} shippingFee={formik.values.delivery_method?.shipping_fee}/>
              </OrderFormSection>
              {cartAmountInfo.isNeededPayment && (
                <OrderFormSection title="お支払い情報の入力">
                  <Field
                    component={PaymentField}
                    name="payment"
                    totalAmount={cartAmountInfo.totalAmount}
                    supportEndAt={orderForm.project.support_end_at}
                    payment={orderForm.payment}
                  />
                </OrderFormSection>
              )}
              {isDeliveryOption && (
                <OrderFormSection title="商品の受け取り方法を選択">
                  <Field component={DeliveryOptionField} name="delivery_method" delivery_options={orderForm.project} totalAmount={cartAmountInfo.totalAmount}/>
                </OrderFormSection>
              )}
              <OrderFormSection title="住民票情報を入力">
                <Field component={ResidenceField} name="recipient" courseIds={courseIds} />
                {onestopDeliverable && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CheckboxField name="onestop_flag" label="ワンストップ特例申請書の送付を希望する" />
                  </div>
                )}
              </OrderFormSection>
              <OrderFormSection title="応援前にご確認下さい">
                <Confirm>
                  <HometownTaxNote />
                  <RuleLiteral {...orderForm.project} />
                  <WritesCommentField name="writes_comment" label="応援コメントを投稿" />
                  <OrderRealizeButtonArea />
                </Confirm>
              </OrderFormSection>
            </Content>
          )}
        </OrderFormHometownTaxFieldset>
      )}
    </Formik>
  );
};
export default withOrderNetworkError(OrderFormHometownTax);

const OrderFormHometownTaxFieldset: FC<{
  orderForm: HometownTaxOrderFormData;
  formik: FormikProps<OrderCreateFormHometownTax>;
  children: FC<{ courseIds: number[]; cartAmountInfo: CartAmountInfo; handleAddDiscount: (discount: Discount) => void }>;
}> = ({ orderForm, formik, children }) => {
  const courseIds = useMemo(() => formik.values.order_items.map((orderItem) => orderItem.course_id), [formik.values.order_items]);
  const cartAmountInfo = useCartAmountInfo(orderForm.cart_items, orderForm.system_commission.amount, formik.values.discounts);
  const handleAddDiscount = useCallback(
    (discount: Discount) => {
      const willNotNeededPayment = cartAmountInfo.totalAmount <= discount.amount;
      if (willNotNeededPayment) formik.setFieldValue("payment", { method: "no_method" });
    },
    [cartAmountInfo.totalAmount, formik.setFieldValue]
  );
  const { triggerOrderFormEvent, triggerPaymentEvent } = useContext(EnhancedEcommerceContext.OrderForm);
  useEffect(() => {
    triggerOrderFormEvent().catch(console.error);
  }, [triggerOrderFormEvent]);
  useEffect(() => {
    if (!cartAmountInfo.isNeededPayment) {
      formik.setFieldValue("payment", { method: "no_method" });
      triggerPaymentEvent("no_method").catch(console.error);
    }
  }, [cartAmountInfo.isNeededPayment, formik.setFieldValue, triggerPaymentEvent]);
  return <fieldset>{children({ courseIds, cartAmountInfo, handleAddDiscount })}</fieldset>;
};

const useOrderFormHometownTaxStates = (orderForm: HometownTaxOrderFormData) => {
  const member = useFrontAuthContext();
  const onestopDeliverable = useMemo(() => !!orderForm.project.onestop_deliverable, [orderForm.project]);
  const initialOnestopFlag = useInitialOnestopFlag(true, onestopDeliverable, member?.onestop_flag);
  const initialPayment = useInitialPayment(orderForm.payment);
  const orderItems = useOrderItems(orderForm.cart_items, member);
  const isDeliveryOption = orderForm.project.delivery_options_flag;
  const initialValues: OrderCreateFormHometownTax = {
    service_type: "hometown_tax",
    order_items: orderItems,
    discounts: [],
    onestop_flag: initialOnestopFlag,
    recipient: orderForm.order_recipient,
    delivery_method: undefined,
    payment: initialPayment,
    writes_comment: true,
  };
  const validationSchema = useMemo(
    () =>
      Yup.object({
        order_items: array().of(
          object().shape({
            details: array().of(
              object().shape({
                course_options: array().defined().of(orderCourseOptionSchema()),
              })
            ),
          })
        ),
        onestop_flag: onestopFlagSchema(true, onestopDeliverable),
        recipient: residenceSchema,
        delivery_method: isDeliveryOption ? Yup.object().shape({
          method: Yup.string().required("配送方法を選択してください。"),
          shipping_fee: Yup.number().notRequired()
        }).required("配送オプションは必須です。") : Yup.mixed().notRequired(),
        payment: Yup.object().required("必須項目です。"),
        writes_comment: Yup.boolean().defined(),
      }).defined(),
    []
  );
  const onSubmit = useOrderFormOnSubmit(orderForm.project.code);
  return {
    onestopDeliverable,
    initialValues,
    validationSchema,
    isDeliveryOption,
    onSubmit,
  };
};
