import { FieldProps } from "formik";
import { OrderRecipient } from "interfaces/front/business";
import { useActivation } from "lib/hooks/useActivation";
import { OrderCreateInput } from "lib/service/client/FrontOrderService";
import React, { FC, useContext } from "react";
import { SsDialogOpenButton } from "../../layout/SsDialogOpenButton";
import GrayBlock from "components/front/order/literal/GrayBlock";
import { ResidenceCard } from "./RecipientCard";
import ResidenceDialog from "./ResidenceDialog";
import EnhancedEcommerceContext from "lib/contexts/EnhancedEcommerceContext";
import ErrorBlock from "components/front/order/literal/ErrorBlock";
import styled from "styled-components";

export type Props = FieldProps<OrderCreateInput> & { courseIds: number[] };
const ResidenceField: FC<Props> = ({ field, form: { errors, values, setFieldValue }, courseIds }) => {
  const [isOpen, open, close] = useActivation(false);
  const { triggerRecipientEvent } = useContext(EnhancedEcommerceContext.OrderForm);
  const openDialog = () => {
    open();
    triggerRecipientEvent().catch(console.error);
  };
  const onInput = (inputRecipient?: OrderRecipient): void => {
    if (inputRecipient) {
      setFieldValue(field.name, inputRecipient);
    }
    close();
  };
  return (
    <>
      <GrayBlock>
        お届け先は住民票の氏名及び住所をご入力ください。
        <br />
        住民票と異なる場合は寄附控除対象にならない可能性があります。
      </GrayBlock>
      <div>
        {errors[field.name] ? (
          <StyledErrorBlock>住民票情報を入力してください</StyledErrorBlock>
        ) : (
          <ResidenceCard residence={values[field.name]} />
        )}
        <SsDialogOpenButton label="住民票情報を入力する" onClick={openDialog} margin="20px auto" />
        <ResidenceDialog isOpen={isOpen} initialValue={values[field.name]} courseIds={courseIds} closeHandle={onInput} />
      </div>
    </>
  );
};
export default ResidenceField;

const StyledErrorBlock = styled(ErrorBlock)`
  margin: 14px 0;
`;
